export const API_BASE_URL = 'https://data.fineartlogistic.com';
export const KEY_PREFIX = 'fineart';

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';

const config = {
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 16,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'it', // 'it' - Italiano, 'en' - English
    rtlLayout: false,
    container: false
};

export default config;
