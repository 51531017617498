// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconReportMoney, IconMailbox, IconTruck, IconUsers, IconShoppingCart, IconCurrencyEuro } from '@tabler/icons';
import * as ROLES from '../roles';

// constant
const icons = {
    IconReportMoney,
    IconMailbox,
    IconTruck,
    IconUsers,
    IconShoppingCart,
    IconCurrencyEuro
};

// ==============================|| CONTENTS MENU ITEMS ||============================== //

const management = {
    id: 'management',
    title: <FormattedMessage id="management" />,
    type: 'group',
    roles: [ROLES.ADMIN, ROLES.CUSTOMER],
    children: [
        {
            id: 'carriers',
            title: <FormattedMessage id="carriers" />,
            type: 'item',
            url: '/carriers',
            icon: icons.IconTruck,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'auction-houses',
            title: <FormattedMessage id="Auctions" />,
            type: 'item',
            url: '/auction-houses',
            icon: icons.IconCurrencyEuro,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'user-billings',
            title: <FormattedMessage id="billing_data" />,
            type: 'item',
            url: '/user-billings',
            icon: icons.IconReportMoney,
            breadcrumbs: false,
            roles: [ROLES.ADMIN, ROLES.CUSTOMER]
        },
        {
            id: 'user-shippings',
            title: <FormattedMessage id="shipping_data" />,
            type: 'item',
            url: '/user-shippings',
            icon: icons.IconMailbox,
            breadcrumbs: false,
            roles: [ROLES.ADMIN, ROLES.CUSTOMER]
        }
        // {
        //     id: 'orders',
        //     title: <FormattedMessage id="orders" />,
        //     type: 'item',
        //     url: '/orders',
        //     icon: icons.IconShoppingCart,
        //     breadcrumbs: false,
        //     roles: [ROLES.ADMIN]
        // }
    ]
};

export default management;
