import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'utils/axios';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';

import useAuth from 'hooks/useAuth';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All'
    },
    {
        value: true,
        label: 'New'
    },
    {
        value: false,
        label: 'Unread'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const { user } = useAuth();
    const [notifications, setNotifications] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        limit: 5,
        offset: 0,
        fields: ['*', 'order.*', 'order.user_created.*'],
        meta: '*',
        sort: '-id',
        search: '',
        filter: ''
    });

    const onFilterClick = (e) => {
        setParams((prev) => {
            const newValue = {
                ...prev,
                filter: {
                    read: e.target.value
                }
            };

            if (e.target.value === 'all') {
                delete newValue.filter.read;
            }

            return newValue;
        });
    };
    const loadNotifications = async () => {
        const res = await axios.get('/items/notifications', {
            params: {
                ...params,
                filter: {
                    ...params.filter,
                    recipient: user?.id
                }
            }
        });
        setNotifications(res.data.data);
        const count = await axios.get('/items/notifications', {
            params: {
                ...params,
                filter: {
                    ...params.filter,
                    recipient: user?.id,
                    read: false
                }
            }
        });
        if (count.data.meta) {
            setTotal(count.data.meta.filter_count);
        }
    };
    const checkNewMessage = notifications.find((item) => item.read === false);
    useEffect(() => {
        const interval = setInterval(() => {
            loadNotifications();
        }, 30000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        loadNotifications();
    }, [params]);
    const notificationId = notifications.map((item) => item.id);

    const onReadAllClick = async () => {
        if (!checkNewMessage) {
            return;
        }
        // get all notifications
        const {
            data: { data: notifications }
        } = await axios.get('/items/notifications', {
            params: {
                filter: {
                    recipient: user?.id,
                    read: false
                },
                limit: -1,
                fields: ['id']
            }
        });
        await axios.patch('/items/notifications', { keys: notifications.map((n) => n.id), data: { read: true } });
        loadNotifications();
    };

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => setValue(event?.target.value);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    position: 'relative',
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <IconBell stroke={1.5} size="21px" />
                </Avatar>
                {total > 0 && (
                    <Chip
                        size="small"
                        label={total}
                        sx={{
                            color: theme.palette.background.default,
                            bgcolor: theme.palette.warning.dark,
                            position: 'absolute',
                            top: '-8px',
                            right: '-8px'
                        }}
                    />
                )}
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    sx={{ pt: 2, px: 2 }}
                                                >
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">
                                                                <FormattedMessage id="all_notifications" />
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            onClick={onReadAllClick}
                                                            component={Link}
                                                            to="#"
                                                            variant="subtitle2"
                                                            color="primary"
                                                        >
                                                            {checkNewMessage ? (
                                                                <FormattedMessage id="mark_all" />
                                                            ) : (
                                                                <FormattedMessage id="no_messages" />
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    <NotificationList
                                                        notifications={notifications}
                                                        reloadNotifications={loadNotifications}
                                                    />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Link style={{ textDecoration: 'none' }} to="/notifications">
                                                <Button size="small" disableElevation>
                                                    <FormattedMessage id="view_all" />
                                                </Button>
                                            </Link>
                                        </CardActions>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
