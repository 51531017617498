// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import {
    IconBrandTelegram,
    IconBuildingStore,
    IconMailbox,
    IconPhoto,
    IconMessageCircle,
    IconWallet,
    IconReceipt,
    IconTruck,
    IconArrowNarrowRight
} from '@tabler/icons';

import User1 from 'assets/images/users/user-round.svg';
import formatDate from 'utils/time';
import { Link } from 'react-router-dom';
import axios from 'utils/axios';
import { useIntl, FormattedMessage } from 'react-intl';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationIcon = ({ notification }) => {
    const theme = useTheme();

    switch (notification.type) {
        case 1:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.primary.main
                    }}
                >
                    <IconMailbox stroke={1.5} size="21px" />
                </Avatar>
            );
        case 2:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.orange.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.orange.main : theme.palette.orange.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.orange.main
                    }}
                >
                    <IconMessageCircle stroke={1.5} size="21px" />
                </Avatar>
            );
        case 3:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.primary.main
                    }}
                >
                    <IconMailbox stroke={1.5} size="21px" />
                </Avatar>
            );
        case 4:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.warning.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.main : theme.palette.warning.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.warning.main
                    }}
                >
                    <IconWallet stroke={1.5} size="21px" />
                </Avatar>
            );
        case 5:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.warning.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.main : theme.palette.warning.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.warning.main
                    }}
                >
                    <IconReceipt stroke={1.5} size="21px" />
                </Avatar>
            );
        case 6:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.primary.main
                    }}
                >
                    <IconTruck stroke={1.5} size="21px" />
                </Avatar>
            );
        case 7:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.primary.main
                    }}
                >
                    <IconTruck stroke={1.5} size="21px" />
                </Avatar>
            );
        case 8:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.primary.main
                    }}
                >
                    <IconTruck stroke={1.5} size="21px" />
                </Avatar>
            );
        case 9:
            return (
                <Avatar
                    sx={{
                        color: theme.palette.warning.dark,
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.main : theme.palette.warning.light,
                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                        borderColor: theme.palette.warning.main
                    }}
                >
                    <IconReceipt stroke={1.5} size="21px" />
                </Avatar>
            );
        default:
            return <> </>;
    }
};

const NotificationMessage = ({ notification }) => {
    switch (notification.type) {
        case 1:
            return <FormattedMessage id="msg_1" />;
        case 2:
            return <FormattedMessage id="msg_2" />;
        case 3:
            return <FormattedMessage id="msg_3" />;
        case 4:
            return <FormattedMessage id="msg_4" />;
        case 5:
            return <FormattedMessage id="msg_5" />;
        case 6:
            return <FormattedMessage id="msg_6" />;
        case 7:
            return <FormattedMessage id="msg_7" />;
        case 8:
            return <FormattedMessage id="msg_8" />;
        case 9:
            return <FormattedMessage id="msg_9" />;
        default:
            return <> </>;
    }
};

const NotificationTitle = ({ notification }) => {
    switch (notification.type) {
        case 1:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_1" />
                </Typography>
            );
        case 2:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_2" />
                </Typography>
            );
        case 3:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_3" />
                </Typography>
            );
        case 4:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_4" />
                </Typography>
            );
        case 5:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_5" />
                </Typography>
            );
        case 6:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_6" />
                </Typography>
            );
        case 7:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_7" />
                </Typography>
            );
        case 8:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_8" />
                </Typography>
            );
        case 9:
            return (
                <Typography style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id="title_9" />
                </Typography>
            );
        default:
            return <> </>;
    }
};

const NotificationList = ({ notifications, reloadNotifications }) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    const onNotificationClick = async (id) => {
        const data = {
            read: true
        };
        await axios.patch(`/items/notifications/${id}`, data);
        reloadNotifications();
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((item) => (
                <Link
                    className="notication"
                    style={{ textDecoration: 'none' }}
                    to={item.order ? `/order-detail/${item.order.id}` : '#'}
                    onClick={() => onNotificationClick(item.id)}
                >
                    <ListItemWrapper sx={{ backgroundColor: item.read === false ? '#E0F2FA' : '' }}>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <NotificationIcon notification={item} />
                            </ListItemAvatar>
                            <ListItemText primary={<NotificationTitle notification={item} order={item.order} />} />
                            <ListItemSecondaryAction>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {item.date_created && formatDate(new Date(item.date_created), true)}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Grid container direction="column" className="list-container" sx={{ mt: 2 }}>
                            <Grid item xs={12} sx={{ pb: 2 }}>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="subtitle2">
                                        <NotificationMessage notification={item} />
                                        <div>
                                            Utente:{` ${item?.order?.user_created?.first_name} ${item?.order?.user_created?.last_name}`}
                                        </div>
                                        <div className="number">{` #${item?.order?.id}`}</div>
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                {[4, 6, 7, 8].includes(item.type) ? (
                                    <Grid container>
                                        <Grid item>
                                            {item.read === false && <Chip label={<FormattedMessage id="unread" />} sx={chipErrorSX} />}
                                        </Grid>
                                        {/* <Grid item>
                                        <Chip label="New" sx={chipWarningSX} />
                                    </Grid> */}
                                    </Grid>
                                ) : (
                                    <Link style={{ textDecoration: 'none' }} to={`/order-detail/${item.order}`}>
                                        {/* <Button
                                            sx={{
                                                ml: 1,
                                                '&.MuiButtonBase-root:hover': {
                                                    bgcolor: 'transparent'
                                                }
                                            }}
                                            variant="contained"
                                            disableElevation
                                            endIcon={<IconArrowNarrowRight stroke={1.5} size="21px" />}
                                        >
                                            Chek it!
                                        </Button> */}
                                    </Link>
                                )}
                            </Grid>
                        </Grid>
                    </ListItemWrapper>
                    <Divider />
                </Link>
            ))}
        </List>
    );
};

export default NotificationList;
