// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconList, IconFilePlus } from '@tabler/icons';
import * as ROLES from '../roles';

// constant
const icons = {
    IconList,
    IconFilePlus
};

// ==============================|| ORDERS MENU ITEMS ||============================== //

const orders = {
    id: 'orders-group',
    title: <FormattedMessage id="orders" />,
    type: 'group',
    roles: [ROLES.ADMIN, ROLES.CUSTOMER, ROLES.AUCTION_HOUSE, ROLES.SHIPPER],
    children: [
        {
            id: 'orders',
            title: <FormattedMessage id="order_list" />,
            type: 'item',
            url: '/orders',
            breadcrumbs: false,
            icon: icons.IconList,
            roles: [ROLES.ADMIN, ROLES.CUSTOMER, ROLES.AUCTION_HOUSE, ROLES.SHIPPER]
        },
        {
            id: 'new-admin',
            title: 'Nuova bozza',
            type: 'item',
            url: '/orders/admin-new',
            breadcrumbs: false,
            icon: icons.IconFilePlus,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'new',
            title: <FormattedMessage id="new_order" />,
            type: 'item',
            url: '/orders/new',
            breadcrumbs: false,
            icon: icons.IconFilePlus,
            roles: [ROLES.CUSTOMER, ROLES.ADMIN]
        }
    ]
};

export default orders;
