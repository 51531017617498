import dashboard from './dashboard';
import management from './management';
import orders from './orders';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, management, orders]
};

export default menuItems;
