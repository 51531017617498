import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { API_BASE_URL, KEY_PREFIX } from 'config';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem(`${KEY_PREFIX}Token`, serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        axios.defaults.baseURL = API_BASE_URL;
    } else {
        localStorage.removeItem(`${KEY_PREFIX}Token`);
        delete axios.defaults.headers.common.Authorization;
        delete axios.defaults.baseURL;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem(`${KEY_PREFIX}Token`);
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.get('/users/me?fields=*,auction_house.*');
                    const user = response.data.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const response = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });
        const serviceToken = response.data.data.access_token;
        setSession(serviceToken);
        const res = await axios.get(`${API_BASE_URL}/users/me?fields=*,auction_house.*`);
        const user = res.data.data;
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const register = async (email, password, firstName, lastName) => {
        let role = '';
        if (email.includes('@conservatorio.it')) {
            role = '9578dbfc-fc91-4bde-ab84-74ba4eb3abde';
        } else if (email.includes('@studenti.conservatorio.it')) {
            role = 'e2165451-0b75-487c-a092-f04e12fcfc67';
        } else {
            role = 'db5ad146-feb5-4744-8fb1-85b7c302d39a';
        }
        const response = await axios.post(`${API_BASE_URL}/users`, {
            role,
            email,
            password,
            first_name: firstName,
            last_name: lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }
        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const requestPassword = async (email) => {
        const params = {
            email,
            reset_url: `${window.location.origin}/reset-password`
        };
        await axios.post(`${API_BASE_URL}/auth/password/request`, params);
    };

    const query = new Proxy(new URLSearchParams(window.location.search), { get: (searchParams, prop) => searchParams.get(prop) });
    const resetPassword = async (password) => {
        const params = {
            password,
            token: query.token
        };
        await axios.post(`${API_BASE_URL}/auth/password/reset`, params);
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, requestPassword, resetPassword, updateProfile }}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
