const formatDate = (data, showTime = false) => {
    const rowData = new Date(data);
    if (showTime)
        return new Intl.DateTimeFormat('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(rowData);
    return new Intl.DateTimeFormat('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).format(rowData);
};

export default formatDate;
