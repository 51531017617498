import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// carriers routing
const Carriers = Loadable(lazy(() => import('views/carriers')));

// users routing
const Users = Loadable(lazy(() => import('views/users')));
const UserDetail = Loadable(lazy(() => import('views/users/userDetail')));

// user billings routing
const UserBillings = Loadable(lazy(() => import('views/user-billings')));

// user shippings routing
const UserShippings = Loadable(lazy(() => import('views/user-shippings')));

// orders
const Orders = Loadable(lazy(() => import('views/orders')));
const NewOrder = Loadable(lazy(() => import('views/orders/new')));
const NewAdminOrder = Loadable(lazy(() => import('views/orders/newAdmin')));
const OrderDetails = Loadable(lazy(() => import('views/orders/OrderDetails/OrderDetails')));

// notifications
const Notifications = Loadable(lazy(() => import('views/notifications')));

// Auctions

const Auctions = Loadable(lazy(() => import('views/auction/index')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/carriers',
            element: <Carriers />
        },
        {
            path: '/auction-houses',
            element: <Auctions />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/me',
            element: <UserDetail />
        },
        {
            path: '/user-billings/user/:id',
            element: <UserBillings />
        },
        {
            path: '/user-shippings/user/:id',
            element: <UserShippings />
        },
        {
            path: '/user-billings',
            element: <UserBillings />
        },
        {
            path: '/user-shippings',
            element: <UserShippings />
        },
        {
            path: '/orders',
            element: <Orders />
        },
        {
            path: '/orders/new',
            element: <NewOrder />
        },
        {
            path: '/orders/admin-new',
            element: <NewAdminOrder />
        },
        {
            path: '/order-detail/:id',
            element: <OrderDetails />
        },
        {
            path: '/notifications',
            element: <Notifications />
        }
    ]
};

export default MainRoutes;
